<template>
    <div class="inquiryDetail">
        <div class="inquiryDetailBox">
            <div class="xunjia">
                <div class="xunjiaBox">
                    <div class="xjTop">
                        <div class="img">
                            <img src="../../assets/homePage/tijiao.png" alt="">
                            <div class="inquirys">
                                <p>订单提交成功</p>
                                <p>您的询价已成功，请查看报价</p>
                            </div>

                        </div>

                        <!-- <div class="xdDate">
                            <p>下单时间</p>
                            <p></p>
                        </div> -->
                        <!-- <div class="orderNumber">
                            <p>订单号</p>
                            <p></p>
                        </div> -->
                        <div class="orderType">
                            <p>订单类型</p>
                            <p>d</p>
                        </div>
                        <!-- <div class="orderType">
                            <p>装箱类型</p>
                            <p></p>
                        </div> -->
                    </div>
                    <div class="tips">
                        <p>温馨提示：您的询价正在处理，请等待我们的报价，您可以在询价界面查看报价，也可以在个人中心-询价订单中查看！</p>
                    </div>
                    <div class="xjButtom">
                        <ul>
                            <li class="lis_1">
                                <div class="startingPoint">
                                    <div class="radius_1">起</div>
                                    <div class="radius_2"></div>
                                    <div class="radius_3"></div>
                                    <div class="radius_4"></div>
                                    <div class="radius_5">到</div>
                                </div>
                                <div class="address">
                                    <p></p>
                                    <p></p>
                                </div>
                            </li>
                            <li class="lis_2">
                                <div>
                                    <p>货好时间</p>
                                    <p></p>
                                </div>
                                <div>
                                    <p>总价</p>
                                    <p style="color: #D4252C;font-size: 14px;">

                                    </p>
                                </div>
                            </li>
                            <li class="lis_2">
                                <div>
                                    <p>期望到达时间</p>
                                    <p>

                                    </p>
                                </div>
                                <div>
                                    <p>费用备注</p>
                                    <p style="color: #D4252C;font-size: 14px;">

                                    </p>

                                </div>
                            </li>
                            <li class="lis_2">
                                <div>
                                    <p>集装箱类型</p>
                                    <p></p>

                                </div>
                                <div>
                                    <p>船期</p>
                                    <p>暂无</p>
                                </div>
                            </li>
                            <li class="lis_2">
                                <div>
                                    <p>贸易方式</p>
                                    <p></p>
                                </div>
                                <div >

                                    <p>报价有效期</p>
                                    <p></p>
                                </div>
                            </li>
                            <li class="lis_2">
                                <div>
                                    <p>仓位类型</p>
                                    <p></p>
                                </div>
                                <div >
                                    <p>备注</p>
                                    <p>{{  }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="detailBottom">
                <div class="previousPage" @click="handlepreviousPage">上一页</div>
                <div class="placeAnOrder1"  @click="handlePlaceAnOrder">立即下单</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "CheckoutSuccess_Component",
    data() {
        return {
            flag: false,
            detailList: this.$route.params.detailList,
            createTime: ""
        }
    },
    created() {

    },
    watch: {
        ...mapState(['token'])
    },
    methods: {
        handlepreviousPage() { //上一页
            this.$router.go(-1);
        },
        handlePlaceAnOrder() {  //立即下单
            var token = sessionStorage.getItem("token")
            // console.log();
            if (token == '' || this.token == "") {
                this.$message({
                    message: '您还没有登录',
                    type: 'success'
                });
                return false
            } else if (this.detailList.cate === "海运订单") {

            } else {

            }
        }
    },
}
</script>

<style lang="scss" scoped>
.inquiryDetail {
    overflow: hidden;
    background: #f6f6f6;
    // height: 100%;
    padding-bottom: 82px;

    .inquiryDetailBox {
        width: 1200px;
        // height: 391px;

        border-radius: 8px 8px 8px 8px;
        overflow: hidden;
        margin: 40px auto 0;

        // .xunjia {
        //     position: absolute;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: rgba(0, 0, 0, 0.3);
        //     z-index: 1000;

        .xunjiaBox {
            position: relative;
            width: 1200px;
            height: 449px;
            // margin: 303px auto;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            padding: 32px;
            overflow: hidden;

            .xjTop {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .img {
                    display: flex;
                    // width: 53px;
                    height: 63px;
                    // background: #2F9BFF;
                    border-radius: 10px 10px 10px 10px;
                    overflow: hidden;

                    img {
                        width: 74px;
                        border-radius: 10px 10px 10px 10px;
                        height: 63px;
                    }
                }

                .inquirys {
                    margin-left: 24px;

                    p:nth-child(1) {
                        font-size: 18px;
                        font-weight: bold;
                        color: #000000;
                    }

                    p:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        margin-top: 12px;
                    }
                }

                .xdDate {
                    p:nth-child(1) {
                        font-size: 14px;
                        font-weight: 400;
                        color: #999999;
                    }

                    p:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        margin-top: 15px;
                    }
                }

                .orderNumber {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;

                    p:nth-child(2) {
                        margin-top: 15px;
                        color: #333333;
                    }
                }

                .orderType {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;

                    p:nth-child(2) {
                        margin-top: 15px;
                        color: #333333;
                    }
                }
            }

            .tips {
                width: 1136px;
                height: 40px;
                background: #FFF2E5;
                margin-top: 25px;
                padding-left: 40px;

                p {
                    font-size: 12px;
                    font-weight: 400;
                    color: #D4252C;
                    line-height: 40px;
                }
            }

            .xjButtom {
                width: 1136px;
                height: 175px;
                border: 1px solid #999999;
                margin-top: 24px;
                padding: 32px 0;

                ul {
                    display: flex;
                    justify-content: space-around;

                    li {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                    }

                    .lis_1 {
                        display: flex;

                        .startingPoint {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            height: 112px;

                            .radius_1,
                            .radius_5 {
                                width: 36px;
                                height: 36px;
                                background: #1890FF;
                                border-radius: 50%;
                                font-size: 12px;
                                font-weight: 400;
                                color: #FFFFFF;
                                text-align: center;
                                line-height: 36px;
                            }

                            .radius_5 {
                                background: #FFC13B;
                            }

                            .radius_2,
                            .radius_3,
                            .radius_4 {
                                width: 4px;
                                height: 4px;
                                background: #999999;
                                border-radius: 50%;
                            }
                        }

                        .address {
                            margin-left: 24px;
                            height: 112px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding: 11px 0;
                            font-size: 14px;
                            font-weight: bold;
                            color: #333333;
                        }
                    }

                    .lis_2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;

                        p:nth-child(2) {
                            margin-top: 5px;
                        }
                    }
                }
            }

            .xjBth {
                position: absolute;
                bottom: 32px;
                right: 32px;
                display: flex;

                .close {
                    width: 100px;
                    height: 34px;
                    background: #F4F4F4;
                    border-radius: 30px 30px 30px 30px;
                    text-align: center;
                    line-height: 34px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 32px;
                }

                .see {
                    width: 100px;
                    height: 34px;
                    background: #1890FF;
                    border-radius: 30px 30px 30px 30px;
                    text-align: center;
                    line-height: 34px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }

        // }

        .detailBottom {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 64px;
            background: #FFFFFF;
            margin-top: 16px;

            .previousPage {
                width: 150px;
                height: 40px;
                border-radius: 30px 30px 30px 30px;
                border: 1px solid #1890FF;
                font-size: 18px;
                font-weight: 400;
                color: #1890FF;
                text-align: center;
                line-height: 40px;
                margin-right: 32px;
                cursor: pointer;
            }

            .placeAnOrder1 {
                width: 150px;
                height: 40px;
                background: #1890FF;
                border-radius: 30px 30px 30px 30px;
                text-align: center;
                line-height: 40px;
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                margin-right: 64px;
                cursor: pointer;
            }

            .placeAnOrder2 {
                width: 150px;
                height: 40px;
                background: #999999;
                border-radius: 30px 30px 30px 30px;
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 40px;
                margin-right: 64px;
                cursor: pointer;
            }
        }
    }
}
</style>