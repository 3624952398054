var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inquiryDetail"},[_c('div',{staticClass:"inquiryDetailBox"},[_c('div',{staticClass:"xunjia"},[_c('div',{staticClass:"xunjiaBox"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"xjButtom"},[_c('ul',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('li',{staticClass:"lis_2"},[_vm._m(7),_c('div',[_c('p',[_vm._v("备注")]),_c('p',[_vm._v(_vm._s())])])])])])])]),_c('div',{staticClass:"detailBottom"},[_c('div',{staticClass:"previousPage",on:{"click":_vm.handlepreviousPage}},[_vm._v("上一页")]),_c('div',{staticClass:"placeAnOrder1",on:{"click":_vm.handlePlaceAnOrder}},[_vm._v("立即下单")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xjTop"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../../assets/homePage/tijiao.png"),"alt":""}}),_c('div',{staticClass:"inquirys"},[_c('p',[_vm._v("订单提交成功")]),_c('p',[_vm._v("您的询价已成功，请查看报价")])])]),_c('div',{staticClass:"orderType"},[_c('p',[_vm._v("订单类型")]),_c('p',[_vm._v("d")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('p',[_vm._v("温馨提示：您的询价正在处理，请等待我们的报价，您可以在询价界面查看报价，也可以在个人中心-询价订单中查看！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lis_1"},[_c('div',{staticClass:"startingPoint"},[_c('div',{staticClass:"radius_1"},[_vm._v("起")]),_c('div',{staticClass:"radius_2"}),_c('div',{staticClass:"radius_3"}),_c('div',{staticClass:"radius_4"}),_c('div',{staticClass:"radius_5"},[_vm._v("到")])]),_c('div',{staticClass:"address"},[_c('p'),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lis_2"},[_c('div',[_c('p',[_vm._v("货好时间")]),_c('p')]),_c('div',[_c('p',[_vm._v("总价")]),_c('p',{staticStyle:{"color":"#D4252C","font-size":"14px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lis_2"},[_c('div',[_c('p',[_vm._v("期望到达时间")]),_c('p')]),_c('div',[_c('p',[_vm._v("费用备注")]),_c('p',{staticStyle:{"color":"#D4252C","font-size":"14px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lis_2"},[_c('div',[_c('p',[_vm._v("集装箱类型")]),_c('p')]),_c('div',[_c('p',[_vm._v("船期")]),_c('p',[_vm._v("暂无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lis_2"},[_c('div',[_c('p',[_vm._v("贸易方式")]),_c('p')]),_c('div',[_c('p',[_vm._v("报价有效期")]),_c('p')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("仓位类型")]),_c('p')])
}]

export { render, staticRenderFns }