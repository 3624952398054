<template>
    <div>
        <div>
            <CheckoutSuccess_Component></CheckoutSuccess_Component>
        </div>
    </div>
</template>

<script>
import CheckoutSuccess_Component from '../../components/bookingSpace/CheckoutSuccess_Component.vue';
export default {
    name: "CheckoutSuccess",
    components: { CheckoutSuccess_Component },
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>

</style>